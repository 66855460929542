export const typesObject = [
    {
        'label': 'TYPESOBJECT.ALIASOBJECT.LABEL',
        'key': 'aliasObject',
        'fieldToDisplay': 'AliasName'
    },
    {
        'label': 'TYPESOBJECT.VARIABLEOBJECT.LABEL',
        'key': 'variableObject',
        'fieldToDisplay': 'Name'
    },
    {
        'label': 'TYPESOBJECT.HEADEROBJECT.LABEL',
        'key': 'HeaderObject',
        'fieldToDisplay': 'Name'
    },
    {
        'label': 'TYPESOBJECT.RESPONSETEMPLATEOBJECT.LABEL',
        'key': 'ResponseTemplateObject',
        'fieldToDisplay': 'ContentType'
    },
    {
        'label': 'TYPESOBJECT.TEMPLATEOBJECT.LABEL',
        'key': 'Template',
        'fieldToDisplay': 'Name'
    },
    {
        'label': 'TYPESOBJECT.METHODINTEGRATIONOBJECT.LABEL',
        'key': 'methodIntegrationObject',
        'fieldToDisplay': 'StatusCode'
    },
    {
        'label': 'TYPESOBJECT.AUTHORIZEROBJECT.LABEL',
        'key': 'authorizerObject',
        'fieldToDisplay': 'AuthID',
        'fieldToOutput': 'AuthID'
    },
    {
        'label': 'TYPESOBJECT.RESPONSEOBJECT.LABEL',
        'key': 'responseObject',
        'fieldToDisplay': 'ResponseType',
    },
    {
        'label': 'TYPESOBJECT.IDENTITYSOURCEOBJECT.LABEL',
        'key': 'identitySourceObject',
        'fieldToDisplay': 'Type'
    },
    {
        'label': 'TYPESOBJECT.PARAMETERSAUTHOBJECTOAUTH.LABEL',
        'name': 'Parámetros OAuth',
        'key': 'parametersAuthObjectOauth',
        'fieldToDisplay': 'Url'
    },
    {
        'label': 'TYPESOBJECT.PARAMETERSAUTHOBJECTJWT.LABEL',
        'name': 'Parámetros JWT',
        'key': 'parametersAuthObjectJwt',
        'fieldToDisplay': 'Secret'
    },
    {
        'label': 'TYPESOBJECT.PARAMETERSAUTHOBJECTBASIC.LABEL',
        'name': 'Parámetros Basic',
        'key': 'parametersAuthObjectBasic',
        'fieldToDisplay': 'Url'
    },
    {
        'label': 'TYPESOBJECT.PARAMETERSAUTHOBJECTCUSTOMTOKEN.LABEL',
        'name': 'Parámetros Custom Token',
        'key': 'parametersAuthObjectCustomToken',
        'fieldToDisplay': 'Function'
    },
    {
        'label': 'TYPESOBJECT.PARAMETERSAUTHOBJECTCUSTOMREQUEST.LABEL',
        'name': 'Parámetros Custom Request',
        'key': 'parametersAuthObjectCustomRequest',
        'fieldToDisplay': 'Function'
    },
    {
        'label': 'TYPESOBJECT.DEPLOYMENTOBJECT.LABEL',
        'key': 'deploymentObject',
        'fieldToDisplay': 'StageName'
    },
    {
        'label': 'TYPESOBJECT.LAMBDAAUTHORIZEROBJECT.LABEL',
        'key': 'lambdaAuthorizerObject',
        'logo': './assets/awslogos/lambda-auth.svg',
        'fieldToDisplay': 'Name'
    },
    {
        'label': 'TYPESOBJECT.RESOURCEOBJECT.LABEL',
        'key': 'resourceObject',
        'name': 'Recurso',
        'logo': './assets/awslogos/resource.svg',
        'fieldToDisplay': 'Name'
    },
    {
        'label': 'TYPESOBJECT.METHODOBJECT.LABEL',
        'key': 'methodObject',
        'CORS': true,
        'fieldToDisplay': 'Method'
    },
    {
        'label': 'TYPESOBJECT.INTEGRACIONPARAMETERSOBJECTLAMBDA.LABEL',
        'name': 'Integración Lambda',
        'key': 'integracionParametersObjectLambda'
    },
    {
        'label': 'TYPESOBJECT.INTEGRACIONPARAMETERSOBJECTLAMBDAPROXY.LABEL',
        'name': 'Integración Lambda Proxy',
        'key': 'integracionParametersObjectProxyLambda'
    },
    {
        'label': 'TYPESOBJECT.INTEGRACIONPARAMETERSOBJECTLAMBDA.LABEL',
        'name': 'Integración Lambda VPC',
        'key': 'integracionParametersObjectProjectVPCLambda'
    },
    {
        'label': 'TYPESOBJECT.INTEGRACIONPARAMETERSOBJECTLAMBDA.LABEL',
        'name': 'Integración Lambda CPD',
        'key': 'integracionParametersObjectCPDVPCLambda'
    },
    {
        'label': 'TYPESOBJECT.INTEGRACIONPARAMETERSOBJECTHTTP.LABEL',
        'name': 'Integración HTTP',
        'key': 'integracionParametersObjectHTTP'
    },
    {
        'label': 'TYPESOBJECT.INTEGRACIONPARAMETERSOBJECTMOCK.LABEL',
        'name': 'Integración Mock',
        'key': 'integracionParametersObjectMock'
    },
    {
        'label': 'TYPESOBJECT.KEYSCHEMAOBJECT.LABEL',
        'key': 'keySchemaObject',
        'fieldToDisplay': 'PartitionKey'
    },
    {
        'label': 'TYPESOBJECT.INDEXOBJECT.LABEL',
        'key': 'indexObject'
    },
    {
        'label': 'TYPESOBJECT.PROJECTIONOBJECT.LABEL',
        'key': 'projectionObject'
    },
    {
        'label': 'TYPESOBJECT.PROVISIONEDOBJECT.LABEL',
        'key': 'provisionedObject'
    },
    {
        'label': 'TYPESOBJECT.BACKUPOBJECT.LABEL',
        'key': 'backupObject'
    },
    {
        'label': 'TYPESOBJECT.STREAMDBOBJECT.LABEL',
        'key': 'streamDBObject'
    },
    {
        'label': 'TYPESOBJECT.STREAMS3OBJECT.LABEL',
        'key': 'streamS3Object'
    },
    {
        'label': 'TYPESOBJECT.STREAMSQSOBJECT.LABEL',
        'key': 'streamSQSObject'
    },
    {
        'label': 'TYPESOBJECT.FILTEROBJECT.LABEL',
        'key': 'filterObject'
    },
    {
        'label': 'TYPESOBJECT.CERTIFICATEOBJECT.LABEL',
        'key': 'certificateObject'
    },
    {
        'label': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.DIMENSIONS.LABEL',
        'key': 'alarmDimensions'
    },
    {
        'label': 'TYPESOBJECT.CONFIGURATIONALARMSOBJECT.LABEL',
        'key': 'configurationAlarmsObject'
    },
    {
        'label': 'TYPESOBJECT.CONFIGURATIONCORS.LABEL',
        'key': 'configurationCORS'
    },
    {
        'label': 'TYPESOBJECT.GRAPHQLAPIOBJECT.LABEL',
        'key': 'graphQLAPIObject'
    },
    {
        'label': 'TYPESOBJECT.GRAPHQLAPILOGCONFIG.LABEL',
        'key': 'graphQLAPILogConfig'
    },
    {
        'label': 'TYPESOBJECT.WSAUTHORIZEROBJECT.LABEL',
        'key': 'WSAuthorizerObject',
        'fieldToDisplay': 'AuthID',
        'fieldToOutput': 'AuthID'
    },
    {
        'label': 'TYPESOBJECT.APIGATEWAYROUTE.LABEL',
        'key': 'apiGatewayRoute',
        'name': 'Ruta',
        'logo': './assets/awslogos/resource.svg',
        'fieldToDisplay': 'Name'
    },
    {
        'label': 'TYPESOBJECT.CONFIGVPC.LABEL',
        'key': 'lambdaConfigVPC',
        'fieldToDisplay': 'label'
    },
    {
        'label': 'TYPESOBJECT.CONFIGVPC.LABEL',
        'key': 'mapfreLambdaConfigVPC',
        'fieldToDisplay': 'label'
    },
    {
        'label': 'OBJECTPROPERTIES.APICONFIGVPC.PROPERTIES.VPC.LABEL',
        'key': 'apiConfigVPC',
        'fieldToDisplay': 'label'
    },
    {
        'label': 'OBJECTPROPERTIES.VPCID.PROPERTIES.VPCID.LABEL',
        'key': 'VPCID',
        'fieldToDisplay': 'label'
    }
]
