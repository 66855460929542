export const typesOption = [
    {
        'label': 'TYPESOPTION.TYPEAUTH.LABEL',
        'key': 'typeAuth',
        'options': [
            {
                'label': 'TYPESOPTION.TYPEAUTH.OPTIONS.TOKEN.LABEL',
                'value': 'TOKEN',
                'actionValue': 'parametersAuthObjectCustomToken'
            },
            {
                'label': 'TYPESOPTION.TYPEAUTH.OPTIONS.REQUEST.LABEL',
                'value': 'REQUEST',
                'actionValue': 'parametersAuthObjectCustomRequest'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.TYPERES.LABEL',
        'key': 'typeRes',
        'options': [
            {
                'label': 'TYPESOPTION.TYPERES.OPTIONS.ACCESS_DENIED.LABEL',
                'value': 'ACCESS_DENIED',
                'actionValue': '403'
            },
            {
                'label': 'TYPESOPTION.TYPERES.OPTIONS.API_CONFIGURATION_ERROR.LABEL',
                'value': 'API_CONFIGURATION_ERROR',
                'actionValue': '500'
            },
            {
                'label': 'TYPESOPTION.TYPERES.OPTIONS.AUTHORIZER_CONFIGURATION_ERROR.LABEL',
                'value': 'AUTHORIZER_CONFIGURATION_ERROR',
                'actionValue': '500'
            },
            {
                'label': 'TYPESOPTION.TYPERES.OPTIONS.AUTHORIZER_FAILURE.LABEL',
                'value': 'AUTHORIZER_FAILURE',
                'actionValue': '500'
            },
            {
                'label': 'TYPESOPTION.TYPERES.OPTIONS.BAD_REQUEST_PARAMETERS.LABEL',
                'value': 'BAD_REQUEST_PARAMETERS',
                'actionValue': '400'
            },
            {
                'label': 'TYPESOPTION.TYPERES.OPTIONS.BAD_REQUEST_BODY.LABEL',
                'value': 'BAD_REQUEST_BODY',
                'actionValue': '400'
            },
            {
                'label': 'TYPESOPTION.TYPERES.OPTIONS.DEFAULT_4XX.LABEL',
                'value': 'DEFAULT_4XX',
                'actionValue': '400'
            },
            {
                'label': 'TYPESOPTION.TYPERES.OPTIONS.DEFAULT_5XX.LABEL',
                'value': 'DEFAULT_5XX',
                'actionValue': '500'
            },
            {
                'label': 'TYPESOPTION.TYPERES.OPTIONS.EXPIRED_TOKEN.LABEL',
                'value': 'EXPIRED_TOKEN',
                'actionValue': '403'
            },
            {
                'label': 'TYPESOPTION.TYPERES.OPTIONS.INTEGRATION_FAILURE.LABEL',
                'value': 'INTEGRATION_FAILURE',
                'actionValue': '504'
            },
            {
                'label': 'TYPESOPTION.TYPERES.OPTIONS.INTEGRATION_TIMEOUT.LABEL',
                'value': 'INTEGRATION_TIMEOUT',
                'actionValue': '504'
            },
            {
                'label': 'TYPESOPTION.TYPERES.OPTIONS.INVALID_API_KEY.LABEL',
                'value': 'INVALID_API_KEY',
                'actionValue': '403'
            },
            {
                'label': 'TYPESOPTION.TYPERES.OPTIONS.INVALID_SIGNATURE.LABEL',
                'value': 'INVALID_SIGNATURE',
                'actionValue': '403'
            },
            {
                'label': 'TYPESOPTION.TYPERES.OPTIONS.MISSING_AUTHENTICATION_TOKEN.LABEL',
                'value': 'MISSING_AUTHENTICATION_TOKEN',
                'actionValue': '403'
            },
            {
                'label': 'TYPESOPTION.TYPERES.OPTIONS.QUOTA_EXCEEDED.LABEL',
                'value': 'QUOTA_EXCEEDED',
                'actionValue': '429'
            },
            {
                'label': 'TYPESOPTION.TYPERES.OPTIONS.REQUEST_TOO_LARGE.LABEL',
                'value': 'REQUEST_TOO_LARGE',
                'actionValue': '413'
            },
            {
                'label': 'TYPESOPTION.TYPERES.OPTIONS.RESOURCE_NOT_FOUND.LABEL',
                'value': 'RESOURCE_NOT_FOUND',
                'actionValue': '404'
            },
            {
                'label': 'TYPESOPTION.TYPERES.OPTIONS.THROTTLED.LABEL',
                'value': 'THROTTLED',
                'actionValue': '404'
            },
            {
                'label': 'TYPESOPTION.TYPERES.OPTIONS.UNAUTHORIZED.LABEL',
                'value': 'UNAUTHORIZED',
                'actionValue': '401'
            },
            {
                'label': 'TYPESOPTION.TYPERES.OPTIONS.UNSUPPORTED_MEDIA_TYPE.LABEL',
                'value': 'UNSUPPORTED_MEDIA_TYPE',
                'actionValue': '415'
            },
            {
                'label': 'TYPESOPTION.TYPERES.OPTIONS.WAF_FILTERED.LABEL',
                'value': 'WAF_FILTERED',
                'actionValue': '403'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.TYPEIDENTITYSOURCE.LABEL',
        'key': 'typeIdentitySource',
        'options': [
            {
                'label': 'TYPESOPTION.TYPEIDENTITYSOURCE.OPTIONS.HEADER.LABEL',
                'value': 'HEADER'
            },
            {
                'label': 'TYPESOPTION.TYPEIDENTITYSOURCE.OPTIONS.QUERY.LABEL',
                'value': 'QUERY'
            },
            {
                'label': 'TYPESOPTION.TYPEIDENTITYSOURCE.OPTIONS.STAGE.LABEL',
                'value': 'STAGE'
            },
            {
                'label': 'TYPESOPTION.TYPEIDENTITYSOURCE.OPTIONS.CONTEXT.LABEL',
                'value': 'CONTEXT'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.TYPEINTEGRATION.LABEL',
        'key': 'typeIntegration',
        'options': [
            {
                'label': 'TYPESOPTION.TYPEINTEGRATION.OPTIONS.LAMBDA.LABEL',
                'value': 'LAMBDA',
                'actionValue': 'integracionParametersObjectLambda'
            },
            {
                'label': 'TYPESOPTION.TYPEINTEGRATION.OPTIONS.LAMBDAPROXY.LABEL',
                'value': 'LAMBDAPROXY',
                'actionValue': 'integracionParametersObjectProxyLambda'
            },
            {
                'label': 'TYPESOPTION.TYPEINTEGRATION.OPTIONS.LAMBDAVPC.LABEL',
                'value': 'PRIVATELAMBDA',
                'actionValue': 'integracionParametersObjectProjectVPCLambda'
            },
            {
                'label': 'TYPESOPTION.TYPEINTEGRATION.OPTIONS.LAMBDACPDVPC.LABEL',
                'value': 'PRIVATELAMBDACPD',
                'actionValue': 'integracionParametersObjectCPDVPCLambda'
            },
            {
                'label': 'TYPESOPTION.TYPEINTEGRATION.OPTIONS.HTTP.LABEL',
                'value': 'HTTP',
                'actionValue': 'integracionParametersObjectHTTP'
            },
            {
                'label': 'TYPESOPTION.TYPEINTEGRATION.OPTIONS.MOCK.LABEL',
                'value': 'MOCK',
                'actionValue': 'integracionParametersObjectMock'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.TYPEINTEGRATION.LABEL',
        'key': 'typeWSIntegration',
        'options': [
            {
                'label': 'TYPESOPTION.TYPEINTEGRATION.OPTIONS.LAMBDAPROXY.LABEL',
                'value': 'LAMBDAPROXY',
                'actionValue': 'integracionParametersObjectProxyLambda'
            },
            {
                'label': 'TYPESOPTION.TYPEINTEGRATION.OPTIONS.HTTP.LABEL',
                'value': 'HTTP',
                'actionValue': 'integracionParametersObjectHTTP'
            },
            {
                'label': 'TYPESOPTION.TYPEINTEGRATION.OPTIONS.MOCK.LABEL',
                'value': 'MOCK',
                'actionValue': 'integracionParametersObjectMock'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.ITEMSTYPE.LABEL',
        'key': 'itemsType',
        'options': [
            {
                'label': 'TYPESOPTION.ITEMSTYPE.OPTIONS.DDB.LABEL',
                'value': 'ddb',
                'actionValue': 'array<node<ddb>>',
                'logoValue': './assets/awslogos/dynamogroup.svg',
                'labelValue': 'TYPESOPTION.ITEMSTYPE.OPTIONS.DDB.LABEL_VALUE'
            },
            {
                'label': 'TYPESOPTION.ITEMSTYPE.OPTIONS.S3.LABEL',
                'value': 's3',
                'actionValue': 'array<node<s3>>',
                'logoValue': './assets/awslogos/s3group.svg',
                'labelValue': 'TYPESOPTION.ITEMSTYPE.OPTIONS.S3.LABEL_VALUE'
            },
            {
                'label': 'TYPESOPTION.ITEMSTYPE.OPTIONS.LAMBDA.LABEL',
                'value': 'lambda',
                'actionValue': 'array<node<lambda>>',
                'logoValue': './assets/awslogos/lambdagroup.svg',
                'labelValue': 'TYPESOPTION.ITEMSTYPE.OPTIONS.LAMBDA.LABEL_VALUE'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.TYPEHTTPMETHOD.LABEL',
        'key': 'typeHttpMethod',
        'options': [
            {
                'label': 'TYPESOPTION.TYPEHTTPMETHOD.OPTIONS.ANY.LABEL',
                'value': 'ANY'
            },
            {
                'label': 'TYPESOPTION.TYPEHTTPMETHOD.OPTIONS.GET.LABEL',
                'value': 'GET'
            },
            {
                'label': 'TYPESOPTION.TYPEHTTPMETHOD.OPTIONS.POST.LABEL',
                'value': 'POST'
            },
            {
                'label': 'TYPESOPTION.TYPEHTTPMETHOD.OPTIONS.PUT.LABEL',
                'value': 'PUT'
            },
            {
                'label': 'TYPESOPTION.TYPEHTTPMETHOD.OPTIONS.DELETE.LABEL',
                'value': 'DELETE'
            },
            {
                'label': 'TYPESOPTION.TYPEHTTPMETHOD.OPTIONS.OPTIONS.LABEL',
                'value': 'OPTIONS'
            },
            {
                'label': 'TYPESOPTION.TYPEHTTPMETHOD.OPTIONS.HEAD.LABEL',
                'value': 'HEAD'
            },
            {
                'label': 'TYPESOPTION.TYPEHTTPMETHOD.OPTIONS.PATCH.LABEL',
                'value': 'PATCH'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.TYPEKEY.LABEL',
        'key': 'typeKey',
        'options': [
            {
                'label': 'TYPESOPTION.TYPEKEY.OPTIONS.N.LABEL',
                'value': 'N'
            },
            {
                'label': 'TYPESOPTION.TYPEKEY.OPTIONS.S.LABEL',
                'value': 'S'
            },
            {
                'label': 'TYPESOPTION.TYPEKEY.OPTIONS.B.LABEL',
                'value': 'B'
            },
            {
                'label': 'TYPESOPTION.TYPEKEY.OPTIONS.BOOL.LABEL',
                'value': 'BOOL'
            },
            {
                'label': 'TYPESOPTION.TYPEKEY.OPTIONS.SS.LABEL',
                'value': 'SS'
            },
            {
                'label': 'TYPESOPTION.TYPEKEY.OPTIONS.NS.LABEL',
                'value': 'NS'
            },
            {
                'label': 'TYPESOPTION.TYPEKEY.OPTIONS.BS.LABEL',
                'value': 'BS'
            },
            {
                'label': 'TYPESOPTION.TYPEKEY.OPTIONS.L.LABEL',
                'value': 'L'
            },
            {
                'label': 'TYPESOPTION.TYPEKEY.OPTIONS.M.LABEL',
                'value': 'M'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.TYPEPROJECTION.LABEL',
        'key': 'typeProjection',
        'options': [
            {
                'label': 'TYPESOPTION.TYPEPROJECTION.OPTIONS.KEYS_ONLY.LABEL',
                'value': 'KEYS_ONLY'
            },
            {
                'label': 'TYPESOPTION.TYPEPROJECTION.OPTIONS.INCLUDE.LABEL',
                'value': 'INCLUDE'
            },
            {
                'label': 'TYPESOPTION.TYPEPROJECTION.OPTIONS.ALL.LABEL',
                'value': 'ALL'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.STARTINGPOSITIONTYPE.LABEL',
        'key': 'startingPositionType',
        'options': [
            {
                'label': 'TYPESOPTION.STARTINGPOSITIONTYPE.OPTIONS.TRIM_HORIZON.LABEL',
                'value': 'TRIM_HORIZON'
            },
            {
                'label': 'TYPESOPTION.STARTINGPOSITIONTYPE.OPTIONS.LATEST.LABEL',
                'value': 'LATEST'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.TYPEEVENTS3.LABEL',
        'key': 'typeEventS3',
        'options': [
            {
                'label': 'TYPESOPTION.TYPEEVENTS3.OPTIONS.S3:OBJECTCREATED:*.LABEL',
                'value': 's3:ObjectCreated:*'
            },
            {
                'label': 'TYPESOPTION.TYPEEVENTS3.OPTIONS.S3:OBJECTCREATED:PUT.LABEL',
                'value': 's3:ObjectCreated:Put'
            },
            {
                'label': 'TYPESOPTION.TYPEEVENTS3.OPTIONS.S3:OBJECTCREATED:POST.LABEL',
                'value': 's3:ObjectCreated:Post'
            },
            {
                'label': 'TYPESOPTION.TYPEEVENTS3.OPTIONS.S3:OBJECTCREATED:COPY.LABEL',
                'value': 's3:ObjectCreated:Copy'
            },
            {
                'label': 'TYPESOPTION.TYPEEVENTS3.OPTIONS.S3:OBJECTREMOVED:*.LABEL',
                'value': 's3:ObjectRemoved:*'
            },
            {
                'label': 'TYPESOPTION.TYPEEVENTS3.OPTIONS.S3:OBJECTREMOVED:DELETE.LABEL',
                'value': 's3:ObjectRemoved:Delete'
            },
            {
                'label': 'TYPESOPTION.TYPEEVENTS3.OPTIONS.S3:OBJECTREMOVED:DELETEMARKERCREATED.LABEL',
                'value': 's3:ObjectRemoved:DeleteMarkerCreated'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.TYPEFILTER.LABEL',
        'key': 'typeFilter',
        'options': [
            {
                'label': 'TYPESOPTION.TYPEFILTER.OPTIONS.SUFFIX.LABEL',
                'value': 'suffix'
            },
            {
                'label': 'TYPESOPTION.TYPEFILTER.OPTIONS.PREFIX.LABEL',
                'value': 'prefix'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.TYPEINDEX.LABEL',
        'key': 'typeIndex',
        'options': [
            {
                'label': 'TYPESOPTION.TYPEINDEX.OPTIONS.GLOBAL.LABEL',
                'value': 'GLOBAL'
            },
            {
                'label': 'TYPESOPTION.TYPEINDEX.OPTIONS.LOCAL.LABEL',
                'value': 'LOCAL'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.TYPENAMESPACE.LABEL',
        'key': 'typeNameSpace',
        'options': [
            {
                'label': 'TYPESOPTION.TYPENAMESPACE.OPTIONS.AWS/APIGATEWAY.LABEL',
                'value': 'AWS/ApiGateway'
            },
            {
                'label': 'TYPESOPTION.TYPENAMESPACE.OPTIONS.AWS/EVENTS.LABEL',
                'value': 'AWS/Events'
            },
            {
                'label': 'TYPESOPTION.TYPENAMESPACE.OPTIONS.AWS/LAMBDA.LABEL',
                'value': 'AWS/Lambda'
            },
            {
                'label': 'TYPESOPTION.TYPENAMESPACE.OPTIONS.AWS/SNS.LABEL',
                'value': 'AWS/SNS'
            },
            {
                'label': 'TYPESOPTION.TYPENAMESPACE.OPTIONS.AWS/S3.LABEL',
                'value': 'AWS/S3'
            },
            {
                'label': 'TYPESOPTION.TYPENAMESPACE.OPTIONS.AWS/STATES.LABEL',
                'value': 'AWS/States'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.TYPEMETRICAPIGATEWAY.LABEL',
        'key': 'typeMetricApiGateway',
        'options': [
            {
                'label': 'TYPESOPTION.TYPEMETRICAPIGATEWAY.OPTIONS.LATENCY.LABEL',
                'value': 'Latency'
            },
            {
                'label': 'TYPESOPTION.TYPEMETRICAPIGATEWAY.OPTIONS.5XXERROR.LABEL',
                'value': '5XXError'
            },
            {
                'label': 'TYPESOPTION.TYPEMETRICAPIGATEWAY.OPTIONS.4XXERROR.LABEL',
                'value': '4XXError'
            },
            {
                'label': 'TYPESOPTION.TYPEMETRICAPIGATEWAY.OPTIONS.COUNT.LABEL',
                'value': 'Count'
            },
            {
                'label': 'TYPESOPTION.TYPEMETRICAPIGATEWAY.OPTIONS.INTEGRATION_LATENCY.LABEL',
                'value': 'IntegrationLatency'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.TYPEMETRICCLOUDFRONT.LABEL',
        'key': 'typeMetricCloudfront',
        'options': [
            {
                'label': 'TYPESOPTION.TYPEMETRICCLOUDFRONT.OPTIONS.REQUESTS.LABEL',
                'value': 'Requests'
            },
            {
                'label': 'TYPESOPTION.TYPEMETRICCLOUDFRONT.OPTIONS.BYTES_UPLOADED.LABEL',
                'value': 'BytesUploaded'
            },
            {
                'label': 'TYPESOPTION.TYPEMETRICCLOUDFRONT.OPTIONS.4XXERROR_RATE.LABEL',
                'value': '4XXErrorRate'
            },
            {
                'label': 'TYPESOPTION.TYPEMETRICCLOUDFRONT.OPTIONS.5XXERROR_RATE.LABEL',
                'value': '5XXErrorRate'
            },
            {
                'label': 'TYPESOPTION.TYPEMETRICCLOUDFRONT.OPTIONS.BYTES_DOWNLOADED.LABEL',
                'value': 'BytesDownloaded'
            },
            {
                'label': 'TYPESOPTION.TYPEMETRICCLOUDFRONT.OPTIONS.TOTAL_ERROR_RATE.LABEL',
                'value': 'TotalErrorRate'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.TYPEMETRICCLOUDWATCHEVENTS.LABEL',
        'key': 'typeMetricCloudWatchEvents',
        'options': [
            {
                'label': 'TYPESOPTION.TYPEMETRICCLOUDWATCHEVENTS.OPTIONS.INVOCATIONS.LABEL',
                'value': 'Invocations'
            },
            {
                'label': 'TYPESOPTION.TYPEMETRICCLOUDWATCHEVENTS.OPTIONS.TRIGGERED_RULES.LABEL',
                'value': 'TriggeredRules'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.TYPEMETRICLAMBDA.LABEL',
        'key': 'typeMetricLambda',
        'options': [
            {
                'label': 'TYPESOPTION.TYPEMETRICLAMBDA.OPTIONS.ERRORS.LABEL',
                'value': 'Errors'
            },
            {
                'label': 'TYPESOPTION.TYPEMETRICLAMBDA.OPTIONS.CONCURRENT_EXECUTIONS.LABEL',
                'value': 'ConcurrentExecutions'
            },
            {
                'label': 'TYPESOPTION.TYPEMETRICLAMBDA.OPTIONS.INVOCATIONS.LABEL',
                'value': 'Invocations'
            },
            {
                'label': 'TYPESOPTION.TYPEMETRICLAMBDA.OPTIONS.DURATION.LABEL',
                'value': 'Duration'
            },
            {
                'label': 'TYPESOPTION.TYPEMETRICLAMBDA.OPTIONS.THROTTLES.LABEL',
                'value': 'Throttles'
            }
        ]
    }, {
        'label': 'TYPESOPTION.TYPEMETRICSNS.LABEL',
        'key': 'typeMetricSNS',
        'options': [
            {
                'label': 'TYPESOPTION.TYPEMETRICSNS.OPTIONS.NUMBER_OF_MESSAGES_PUBLISHED.LABEL',
                'value': 'NumberOfMessagesPublished'
            },
            {
                'label': 'TYPESOPTION.TYPEMETRICSNS.OPTIONS.PUBLISH_SIZE.LABEL',
                'value': 'PublishSize'
            },
            {
                'label': 'TYPESOPTION.TYPEMETRICSNS.OPTIONS.NUMBER_OF_NOTIFICATIONS_DELIVERED.LABEL',
                'value': 'NumberOfNotificationsDelivered'
            },
            {
                'label': 'TYPESOPTION.TYPEMETRICSNS.OPTIONS.NUMBER_OF_NOTIFICATIONS_FAILED.LABEL',
                'value': 'NumberOfNotificationsFailed'
            }
        ]
    }, {
        'label': 'TYPESOPTION.TYPEMETRICS3.LABEL',
        'key': 'typeMetricS3',
        'options': [
            {
                'label': 'TYPESOPTION.TYPEMETRICS3.OPTIONS.BUCKET_SIZE_BYTES.LABEL',
                'value': 'BucketSizeBytes'
            },
            {
                'label': 'TYPESOPTION.TYPEMETRICS3.OPTIONS.NUMBER_OF_OBJECTS.LABEL',
                'value': 'NumberOfObjects'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.TYPEMETRICSTEPFUNCTION.LABEL',
        'key': 'typeMetricStepFunction',
        'options': [
            {
                'label': 'TYPESOPTION.TYPEMETRICSTEPFUNCTION.OPTIONS.EXECUTION_TIME.LABEL',
                'value': 'ExecutionTime'
            },
            {
                'label': 'TYPESOPTION.TYPEMETRICSTEPFUNCTION.OPTIONS.EXECUTION_THROTTLED.LABEL',
                'value': 'ExecutionThrottled'
            },
            {
                'label': 'TYPESOPTION.TYPEMETRICSTEPFUNCTION.OPTIONS.EXECUTIONS_FAILED.LABEL',
                'value': 'ExecutionsFailed'
            },
            {
                'label': 'TYPESOPTION.TYPEMETRICSTEPFUNCTION.OPTIONS.EXECUTIONS_ABORTED.LABEL',
                'value': 'ExecutionsAborted'
            },
            {
                'label': 'TYPESOPTION.TYPEMETRICSTEPFUNCTION.OPTIONS.EXECUTIONS_SUCCEEDED.LABEL',
                'value': 'ExecutionsSucceed'
            },
            {
                'label': 'TYPESOPTION.TYPEMETRICSTEPFUNCTION.OPTIONS.EXECUTIONS_TIMED_OUT.LABEL',
                'value': 'ExecutionsTimedOut'
            },
            {
                'label': 'TYPESOPTION.TYPEMETRICSTEPFUNCTION.OPTIONS.EXECUTIONS_STARTED.LABEL',
                'value': 'ExecutionsStarted'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.TYPECOMPARISIONOPERATOR.LABEL',
        'key': 'typeComparisionOperator',
        'options': [
            {
                'label': 'TYPESOPTION.TYPECOMPARISIONOPERATOR.OPTIONS.GREATERTHANOREQUALTOTHRESHOLD.LABEL',
                'value': 'GreaterThanOrEqualToThreshold'
            },
            {
                'label': 'TYPESOPTION.TYPECOMPARISIONOPERATOR.OPTIONS.GREATERTHANTHRESHOLD.LABEL',
                'value': 'GreaterThanThreshold'
            },
            {
                'label': 'TYPESOPTION.TYPECOMPARISIONOPERATOR.OPTIONS.LESSTHANTHRESHOLD.LABEL',
                'value': 'LessThanThreshold'
            },
            {
                'label': 'TYPESOPTION.TYPECOMPARISIONOPERATOR.OPTIONS.LESSTHANOREQUALTOTHRESHOLD.LABEL',
                'value': 'LessThanOrEqualToThreshold'
            },
            {
                'label': 'TYPESOPTION.TYPECOMPARISIONOPERATOR.OPTIONS.LESSTHANLOWERORGREATERTHANUPPERTHRESHOLD.LABEL',
                'value': 'LessThanLowerOrGreaterThanUpperThreshold'
            },
            {
                'label': 'TYPESOPTION.TYPECOMPARISIONOPERATOR.OPTIONS.LESSTHANLOWERTHRESHOLD.LABEL',
                'value': 'LessThanLowerThreshold'
            },
            {
                'label': 'TYPESOPTION.TYPECOMPARISIONOPERATOR.OPTIONS.GREATERTHANUPPERTHRESHOLD.LABEL',
                'value': 'GreaterThanUpperThreshold'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.TYPESTATISTIC.LABEL',
        'key': 'typeStatistic',
        'options': [
            {
                'label': 'TYPESOPTION.TYPESTATISTIC.OPTIONS.MINIMUM.LABEL',
                'value': 'Minimum'
            },
            {
                'label': 'TYPESOPTION.TYPESTATISTIC.OPTIONS.MAXIMUM.LABEL',
                'value': 'Maximum'
            },
            {
                'label': 'TYPESOPTION.TYPESTATISTIC.OPTIONS.SUM.LABEL',
                'value': 'Sum'
            },
            {
                'label': 'TYPESOPTION.TYPESTATISTIC.OPTIONS.AVERAGE.LABEL',
                'value': 'Average'
            },
            {
                'label': 'TYPESOPTION.TYPESTATISTIC.OPTIONS.SAMPLECOUNT.LABEL',
                'value': 'SampleCount'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.TYPESEVERITY.LABEL',
        'key': 'typeSeverity',
        'options': [
            {
                'label': 'TYPESOPTION.TYPESEVERITY.OPTIONS.CRITICAL.LABEL',
                'value': 'CRITICAL'
            },
            {
                'label': 'TYPESOPTION.TYPESEVERITY.OPTIONS.MAJOR.LABEL',
                'value': 'MAJOR'
            },
            {
                'label': 'TYPESOPTION.TYPESEVERITY.OPTIONS.MINOR.LABEL',
                'value': 'MINOR'
            },
            {
                'label': 'TYPESOPTION.TYPESEVERITY.OPTIONS.WARNING.LABEL',
                'value': 'WARNING'
            },
            {
                'label': 'TYPESOPTION.TYPESEVERITY.OPTIONS.OK.LABEL',
                'value': 'OK'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.TYPEENV.LABEL',
        'key': 'typeEnv',
        'options': [
            {
                'label': 'TYPESOPTION.TYPEENV.OPTIONS.INT.LABEL',
                'value': 'int'
            },
            {
                'label': 'TYPESOPTION.TYPEENV.OPTIONS.PRE.LABEL',
                'value': 'pre'
            },
            {
                'label': 'TYPESOPTION.TYPEENV.OPTIONS.PRO.LABEL',
                'value': 'pro'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.TYPEARCHITECTURE.LABEL',
        'key': 'typeArchitecture',
        'options': [
            {
                'label': 'TYPESOPTION.TYPEARCHITECTURE.OPTIONS.DRAW&BAAS.LABEL',
                'value': 'Draw&BaaS'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.TYPECERTIFICATE.LABEL',
        'key': 'typeCertificate',
        'options': [
            {
                'label': 'TYPESOPTION.TYPECERTIFICATE.OPTIONS.IMPORT.LABEL',
                'value': 'IMPORT'
            },
            {
                'label': 'TYPESOPTION.TYPECERTIFICATE.OPTIONS.AUTO_AWS.LABEL',
                'value': 'AUTO_AWS'
            },
            {
                'label': 'TYPESOPTION.TYPECERTIFICATE.OPTIONS.DEFAULT.LABEL',
                'value': 'DEFAULT'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.GRAPHQLAPIAUTHTYPE.LABEL',
        'key': 'graphQLAPIAuthType',
        'options': [
            {
                'label': 'TYPESOPTION.GRAPHQLAPIAUTHTYPE.OPTIONS.API_KEY.LABEL',
                'value': 'API_KEY'
            },
            {
                'label': 'TYPESOPTION.GRAPHQLAPIAUTHTYPE.OPTIONS.AWS_IAM.LABEL',
                'value': 'AWS_IAM'
            },
            {
                'label': 'TYPESOPTION.GRAPHQLAPIAUTHTYPE.OPTIONS.AMAZON_COGNITO_USER_POOLS.LABEL',
                'value': 'AMAZON_COGNITO_USER_POOLS'
            },
            {
                'label': 'TYPESOPTION.GRAPHQLAPIAUTHTYPE.OPTIONS.OPENID_CONNECT.LABEL',
                'value': 'OPENID_CONNECT'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.APPSYNCLOGLEVEL.LABEL',
        'key': 'AppSyncLogLevel',
        'options': [
            {
                'label': 'TYPESOPTION.APPSYNCLOGLEVEL.OPTIONS.NONE.LABEL',
                'value': 'NONE'
            },
            {
                'label': 'TYPESOPTION.APPSYNCLOGLEVEL.OPTIONS.ERROR.LABEL',
                'value': 'ERROR'
            },
            {
                'label': 'TYPESOPTION.APPSYNCLOGLEVEL.OPTIONS.ALL.LABEL',
                'value': 'ALL'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.VPCTYPE.LABEL',
        'key': 'VPCType',
        'options': [
            {
                'label': 'TYPESOPTION.VPCTYPE.OPTIONS.INTERNET.LABEL',
                'value': 'INTERNET',
                'logoValue': './assets/awslogos/lambda.svg',
            },
            {
                'label': 'TYPESOPTION.VPCTYPE.OPTIONS.PRIVATE.LABEL',
                'value': 'PRIVATE',
                'logoValue': './assets/awslogos/privatelambda.svg',
            }
        ]
    },
    {
        'label': 'TYPESOPTION.TYPELAMBDARUNTIMES.LABEL',
        'key': 'typeLambdaRuntimes',
        'options': [
            {
                'label': 'TYPESOPTION.TYPELAMBDARUNTIMES.OPTIONS.NODEJS20.X.LABEL',
                'value': 'nodejs20.x'
            },
            {
                'label': 'TYPESOPTION.TYPELAMBDARUNTIMES.OPTIONS.NODEJS18.X.LABEL',
                'value': 'nodejs18.x'
            },
            {
                'label': 'TYPESOPTION.TYPELAMBDARUNTIMES.OPTIONS.NODEJS16.X.LABEL',
                'value': 'nodejs16.x'
            },
            {
                'label': 'TYPESOPTION.TYPELAMBDARUNTIMES.OPTIONS.PYTHON3.9.LABEL',
                'value': 'python3.9'
            },
            {
                'label': 'TYPESOPTION.TYPELAMBDARUNTIMES.OPTIONS.PYTHON3.8.LABEL',
                'value': 'python3.8'
            },
            {
                'label': 'TYPESOPTION.TYPELAMBDARUNTIMES.OPTIONS.PYTHON3.7.LABEL',
                'value': 'python3.7'
            },
            {
                'label': 'TYPESOPTION.TYPELAMBDARUNTIMES.OPTIONS.PYTHON3.6.LABEL',
                'value': 'python3.6'
            },
            {
                'label': 'TYPESOPTION.TYPELAMBDARUNTIMES.OPTIONS.JAVA11.LABEL',
                'value': 'java11'
            },
            {
                'label': 'TYPESOPTION.TYPELAMBDARUNTIMES.OPTIONS.JAVA8.LABEL',
                'value': 'java8'
            },
            {
                'label': 'TYPESOPTION.TYPELAMBDARUNTIMES.OPTIONS.RUBY2.7.LABEL',
                'value': 'ruby2.7'
            },
            {
                'label': 'TYPESOPTION.TYPELAMBDARUNTIMES.OPTIONS.GO1.X.LABEL',
                'value': 'go1.x'
            },
            {
                'label': 'TYPESOPTION.TYPELAMBDARUNTIMES.OPTIONS.DOTNET6.LABEL',
                'value': 'dotnet6'
            },
            {
                'label': 'TYPESOPTION.TYPELAMBDARUNTIMES.OPTIONS.DOTNETCORE3.1.LABEL',
                'value': 'dotnetcore3.1'
            },
            {
                'label': 'TYPESOPTION.TYPELAMBDARUNTIMES.OPTIONS.PROVIDED.LABEL',
                'value': 'provided'
            }
        ]
    },
    {
        'label': 'TYPESOPTION.TYPERETENTIONLOGSPERIOD.LABEL',
        'key': 'retentionLogsPeriod',
        'options': [
            {
                'label': 'TYPESOPTION.TYPERETENTIONLOGSPERIOD.OPTIONS.NEVER.LABEL',
                'value': 'NEVER'
            },
            {
                'label': 'TYPESOPTION.TYPERETENTIONLOGSPERIOD.OPTIONS.1DAY.LABEL',
                'value': '1'
            },
            {
                'label': 'TYPESOPTION.TYPERETENTIONLOGSPERIOD.OPTIONS.3DAYS.LABEL',
                'value': '3'
            },
            {
                'label': 'TYPESOPTION.TYPERETENTIONLOGSPERIOD.OPTIONS.5DAYS.LABEL',
                'value': '5'
            },
            {
                'label': 'TYPESOPTION.TYPERETENTIONLOGSPERIOD.OPTIONS.1WEEK.LABEL',
                'value': '7'
            },
            {
                'label': 'TYPESOPTION.TYPERETENTIONLOGSPERIOD.OPTIONS.2WEEKS.LABEL',
                'value': '14'
            },
            {
                'label': 'TYPESOPTION.TYPERETENTIONLOGSPERIOD.OPTIONS.1MONTH.LABEL',
                'value': '30'
            },
            {
                'label': 'TYPESOPTION.TYPERETENTIONLOGSPERIOD.OPTIONS.2MONTHS.LABEL',
                'value': '60'
            },
            {
                'label': 'TYPESOPTION.TYPERETENTIONLOGSPERIOD.OPTIONS.3MONTHS.LABEL',
                'value': '90'
            },
            {
                'label': 'TYPESOPTION.TYPERETENTIONLOGSPERIOD.OPTIONS.4MONTHS.LABEL',
                'value': '120'
            },
            {
                'label': 'TYPESOPTION.TYPERETENTIONLOGSPERIOD.OPTIONS.5MONTHS.LABEL',
                'value': '150'
            },
            {
                'label': 'TYPESOPTION.TYPERETENTIONLOGSPERIOD.OPTIONS.6MONTHS.LABEL',
                'value': '180'
            },
            {
                'label': 'TYPESOPTION.TYPERETENTIONLOGSPERIOD.OPTIONS.12MONTHS.LABEL',
                'value': '365'
            },
            {
                'label': 'TYPESOPTION.TYPERETENTIONLOGSPERIOD.OPTIONS.13MONTHS.LABEL',
                'value': '400'
            },
            {
                'label': 'TYPESOPTION.TYPERETENTIONLOGSPERIOD.OPTIONS.18MONTHS.LABEL',
                'value': '545'
            },
            {
                'label': 'TYPESOPTION.TYPERETENTIONLOGSPERIOD.OPTIONS.2YEARS.LABEL',
                'value': '731'
            },
            {
                'label': 'TYPESOPTION.TYPERETENTIONLOGSPERIOD.OPTIONS.3YEARS.LABEL',
                'value': '1096'
            },
            {
                'label': 'TYPESOPTION.TYPERETENTIONLOGSPERIOD.OPTIONS.5YEARS.LABEL',
                'value': '1827'
            },
            {
                'label': 'TYPESOPTION.TYPERETENTIONLOGSPERIOD.OPTIONS.6YEARS.LABEL',
                'value': '2192'
            },
            {
                'label': 'TYPESOPTION.TYPERETENTIONLOGSPERIOD.OPTIONS.7YEARS.LABEL',
                'value': '2557'
            },
            {
                'label': 'TYPESOPTION.TYPERETENTIONLOGSPERIOD.OPTIONS.8YEARS.LABEL',
                'value': '2922'
            },
            {
                'label': 'TYPESOPTION.TYPERETENTIONLOGSPERIOD.OPTIONS.9YEARS.LABEL',
                'value': '3288'
            },
            {
                'label': 'TYPESOPTION.TYPERETENTIONLOGSPERIOD.OPTIONS.10YEARS.LABEL',
                'value': '3653'
            }
        ]
    }
];
