import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import * as _ from 'lodash';
import { GeneralService } from '../../../core/services/general.service';

@Component({
  selector: 'app-route-apigw',
  templateUrl: './route-apigw.component.html',
  styleUrls: ['./route-apigw.component.scss']
})
export class RouteApiGWComponent implements OnInit {

  private _resource: any = {};

  @Input()
  get resource() {
    return this._resource;
  }
  set resource(newValue) {
    this._resource = newValue;
    this.resourceChange.emit(this._resource);
  }

  @Output()
  resourceChange: EventEmitter<any> = new EventEmitter<any>();


  @Input()
  folioCanvasRef: any;

  @ViewChild('mainNode')
  mainNodeRef: ElementRef;

  @ViewChildren('resourceApiRef')
  resourcesApiRef: QueryList<ElementRef>;

  @ViewChildren('integrationRef')
  integrationsRef: QueryList<ElementRef>;

  @Output()
  resourceClick: EventEmitter<any> = new EventEmitter<any>();

  isContentCollapsed: boolean = false;

  constructor(private generalService: GeneralService) { }

  ngOnInit() {
    this.generalService.notifyNodeChangeObservable$.subscribe((data) => {
      if (
        ((data.change === 'itemAdded' || data.change === 'itemDeleted') && (
          data.property.key === 'Routes'
          || data.property.key === 'Authorizers')
        )
        || (data.change === 'propertyChanged' && data.property.key === 'Integration')
        || (data.change === 'editingComplex' && data.property.key === 'IntegrationParameters')
      ) {
        this.redrawLines('integration');
      }
    });
  }

  ngAfterViewInit() {
    this.redrawLines('integration');
  }

  redrawLines(target) {
    if (target === 'integration') {
      setTimeout(() => {
        _.forEach(this.integrationsRef.toArray(), (integrationRef: ElementRef, index) => {
          const folioNativeElement = this.folioCanvasRef.nativeElement;
          const routeNativeElement = this.mainNodeRef.nativeElement.children[0].children[0];
          if (this.integrationsRef.toArray()[index]) {
            const integrationNativeElement = this.integrationsRef.toArray()[index].nativeElement.children[0].children[0];
            if (this.resource.IntegrationParameters) {
              this.resource.IntegrationParameters.arrow = this.generalService.generateLine(folioNativeElement, routeNativeElement, integrationNativeElement, 'right', 'left');
            }
          }
        })
      }, 333);
    }
  }

  getIntegrationLogo(integrationType) {
    let urlLogo;
    if(integrationType === "LAMBDA" || integrationType === "LAMBDAPROXY"){
      urlLogo = "./assets/awslogos/lambda.svg"
    }else if(integrationType === 'HTTP'){
      urlLogo = "./assets/awslogos/http.svg"
    }else if(integrationType === 'PRIVATELAMBDA'){
      urlLogo = "./assets/awslogos/privatelambda.svg"
    }else if(integrationType === 'PRIVATELAMBDACPD'){
      urlLogo = "./assets/awslogos/privatelambda.svg"
    }else{
      urlLogo = "./assets/awslogos/mock.svg"
    }
    return urlLogo;
  }

  onResourceClick(resource) {
    this.resourceClick.emit(resource);
  }

  onCollapseClick() {
    this.isContentCollapsed = !this.isContentCollapsed
    this.generalService.notifyCollapseChange();
  }

}
